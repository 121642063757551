<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!-- SLIDER -->
        <q-carousel
            arrows
            animated
            infinite
            :autoplay="5000"
            :swipeable="true"
            width="100%"
            height="600px" v-model="slide">
            <q-carousel-slide name="1" class="flex justify-center" img-src="@/assets/media/slides/ibc-international-bio-cosmetics-social-media-slide-2.jpg">
                <div class="my-auto mx-auto  w-1/2">
                    <h1 class="text-center text-white text-7xl font-black font-epigrafica uppercase">MOTIVATED BY BEAUTY</h1>
                    <h1 class="text-white text-center text-2xl mt-2">We reveal your beauty to the world</h1>
                </div>
            </q-carousel-slide>
            <q-carousel-slide name="2" class="flex justify-center" img-src="@/assets/media/slides/ibc-international-bio-cosmetics-social-media-slide-1.jpg">
                <div class="my-auto mx-auto w-1/2">
                    <h1 class="text-center text-white text-7xl font-black font-epigrafica uppercase">INSPIRED BY NATURE</h1>
                    <h1 class="text-white text-center text-2xl mt-2">Thanks to natural ingredients</h1>
                </div>
            </q-carousel-slide>
            <q-carousel-slide name="3" class="flex justify-center" img-src="@/assets/media/slides/ibc-international-bio-cosmetics-social-media-slide-3.jpg">
                <div class="my-auto mx-auto  w-1/2">
                    <h1 class="text-center text-white text-7xl font-black font-epigrafica uppercase">GUIDED BY QUALITY</h1>
                    <h1 class="text-white text-center text-2xl mt-2">To produce the best you deserve</h1>
                </div>
            </q-carousel-slide>
        </q-carousel>   

        <!-- ENGAGEMENTS -->
        <div class="container mx-auto py-20">
            <div class="row justify-around">
                <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                    <q-img fit="cover" width="100px" src="@/assets/media/engagements/ibc-international-bio-cosmetics-engagements-icon-save-earth.svg" no-spinner no-transition/>
                    <p class="text-mineralgreen text-center text-base mt-2 px-20">Respectful of nature</p>
                </div>
                <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                    <q-img fit="cover" width="100px" src="@/assets/media/engagements/ibc-international-bio-cosmetics-engagements-icon-100-natural-ingredients.svg" no-spinner no-transition/>
                    <p class="text-mineralgreen text-center text-base mt-2 px-20">90% natural ingredients</p>
                </div>
                <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                    <q-img fit="cover" width="100px" src="@/assets/media/engagements/ibc-international-bio-cosmetics-engagements-icon-tested-derma.svg" no-spinner no-transition/>
                    <p class="text-mineralgreen text-center text-base mt-2 px-20">Tested and approved by dermatologists</p>
                </div>
                <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                    <q-img fit="cover" width="100px" src="@/assets/media/engagements/ibc-international-bio-cosmetics-engagements-icon-packs-bio.svg" no-spinner no-transition/>
                    <p class="text-mineralgreen text-center text-base mt-2 px-20">Eco-responsible bottles and cases</p>
                </div>
            </div>
        </div>

        <!-- SUMMARY PRESENTATION -->
        <div class="container mx-auto py-10">
            <div class="row">
                <div class="col-12 flex flex-col items-center">
                    <h1 class="text-mineralgreen text-center text-4xl font-black font-epigrafica mt-2 uppercase">YOUR NATURAL COSMETICS CREATOR</h1>
                    <div class="bg-skincolor p-1 mt-3 w-72"></div>
                </div>
                <div class="row q-pa-sm items-center justify-center py-20">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 px-12">
                        <q-img width="100%" src="@/assets/media/brands/ibc-international-bio-cosmetics-default-vertical-logotype.svg" no-spinner />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 p-5">
                        <p class="font-epigrafica text-mineralgreen text-xl text-justify font-medium mb-5">
                            International Bio Cosmetics (IBC) is a company specialized in the manufacture, production and marketing of cosmetic products essentially based on natural ingredients and plants.<br><br>
                            
                            Created and established in Ivory Coast since 2019, IBC offers through the expertise of its laboratories, effective and innovative beauty products inspired by our grandmother's recipes.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-skincolor p-1"></div>

        <!-- SECTION PREVIEWER -->
        <!----------------------->
        <!-- SPEAK ABOUT BIO -->
        <div class="py-14 bg-cover bg-nature-drop-water row">
            <div class="col-12 flex flex-col items-center">
                <h1 class="text-white text-center text-4xl font-black font-epigrafica mt-2 uppercase">Let's talk cosmetics !</h1>
                <div class="bg-skincolor p-1 mt-3 w-72"></div>
            </div>
            <div class="container mx-auto py-16">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 p-5">
                        <p class="text-white text-3xl font-black font-epigrafica my-4 uppercase">Nature is our strength !</p>
                        <p class="font-epigrafica text-white text-xl font-medium">
                            Most of the ingredients in our formulas are of natural origin. We have found our beauty treasures in the  local soil. Thus we are committed to taking care of your skin but also of nature.<br><br>
                            If our formulas are respectful of your skin's health, we have also paid particular attention to the creation of our packaging: they are all designed to limit waste and pollution as much as possible.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-skincolor p-1"></div>

        <!-- FEATURED CONTENT -->
        <div class="container mx-auto py-36">
            <div class="row justify-around">
                <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                    <div class="col-12 flex flex-col items-center mb-14">
                        <h1 class="text-mineralgreen text-center text-2xl font-black font-epigrafica mt-2 uppercase">Ingredient of the month</h1>
                        <div class="bg-skincolor p-1 mt-3 w-20"></div>
                    </div>
                    <div class="static row justify-around">
                        <q-img class="drop-shadow-xl" width="100%" src="@/assets/media/posts/ibc-international-bio-cosmetics-ingredient-du-mois-avocat.jpg" no-spinner />
                        <div class="bg- h-4 w-11/12"></div>
                    </div>
                    <div>
                        <p class="text-skincolor text-xl font-semibold mt-5">The regenerative power of avocado</p>
                        <p class="text-mineralgreen text-lg text-justify break-words my-3">
                            Delicious, the avocado is a good fruit for the body, but it is also excellent for the epidermis and the hair fiber. Composed of vitamins A, C and E, the avocado is also rich in nutrients...
                        </p>
                        <q-btn to="/page/en/ingredient/3/avocat" unelevated label="Read more" class="bg-skincolor hover:bg-mineralgreen text-white font-bold py-2 px-4 rounded-full capitalize" />
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                    <div class="col-12 flex flex-col items-center mb-14">
                        <h1 class="text-mineralgreen text-center text-2xl font-black font-epigrafica mt-2 uppercase">Expert advice of the day</h1>
                        <div class="bg-skincolor p-1 mt-3 w-20"></div>
                    </div>
                    <div class="static row justify-around">
                        <q-img class="drop-shadow-xl" width="100%" src="@/assets/media/posts/ibc-international-bio-cosmetics-conseil-expert-du-jour-masque-carotte.jpg" no-spinner />
                        <div class="bg- h-4 w-11/12"></div>
                    </div>
                    <div>
                        <p class="text-skincolor text-xl font-semibold mt-5">Homemade Carrot Anti-Wrinkle Mask</p>
                        <p class="text-mineralgreen text-lg text-justify break-words my-3">
                            This homemade Carrot anti-wrinkle mask will brighten your complexion! Beta-carotene (which gives it its orange color) and vitamin C revitalize the skin... and give it a healthy glow instantly!...
                        </p>
                        <q-btn to="/page/en/expert-counseling/1/masque-antirides-a-la-carotte-fait-maison" unelevated label="Read more" class="bg-skincolor hover:bg-mineralgreen text-white font-bold py-2 px-4 rounded-full capitalize" />
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-skincolor p-1"></div>

        <!-- INSTAGRAM CONTENT -->
        <div class="row bg-lighty pt-9 pb-24">
            <div class="col-12 flex flex-col items-center mb-5">
                <p class="text-mineralgreen text-center text-2xl font-black font-epigrafica mt-2 uppercase">International Bio Cosmetics on Instagram</p>
                <p class="text-mineralgreen-dark text-lg text-center break-words">@ibiocosmetics</p>
            </div>
            <div class="col-12">
                <q-carousel
                    v-model="slide2"
                    transition-prev="slide-right"
                    transition-next="slide-left"
                    animated
                    infinite
                    height="400px"
                    class="bg-transparent">
                        <q-carousel-slide name="1" class="colrowumn p-0 no-wrap">
                            <div class="row fit justify-start items-center no-wrap bg-transparent">
                                <q-img class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 full-height" src="@/assets/media/instagram/ibc-international-bio-cosmetics-instagram-1.jpg" />
                                <q-img class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 full-height" src="@/assets/media/instagram/ibc-international-bio-cosmetics-instagram-2.jpg" />
                                <q-img class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 full-height" src="@/assets/media/instagram/ibc-international-bio-cosmetics-instagram-3.jpg" />
                                <q-img class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 full-height" src="@/assets/media/instagram/ibc-international-bio-cosmetics-instagram-4.jpg" />
                            </div>
                        </q-carousel-slide>
                </q-carousel>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/en/Navbar"
import footerbar from "@/views/shared/en/Footer"
import { useMeta } from "quasar"

export default {
    name: "Index",
    components: { navbar, footerbar },
    data() {
        return {
            slide: '1',
            slide2: '1',
        }
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Accueil', // sets document title
        })
    }
};
</script>